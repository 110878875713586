import React, { FC, useCallback, useEffect, useState } from 'react';
import InnerContainer from '../../components/InnerContainer';
import Button from '../../components/Button';
import * as styles from './styles.module.scss';
import GetGiftModal from './_components/GetGiftModal';
import queryString from 'query-string';
import { useWalletStore } from '../WalletProvider/context';

interface ITransaction {
  search: string;
}

const TransactionGiftContainer: FC<ITransaction> = ({ search }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [transactionHash, setTransactionHash] = useState<string>('');

  const { contract } = useWalletStore();

  useEffect(() => {
    if (!contract) return;
    const queryParams: any = queryString.parse(search?.slice(1));
    setTransactionHash(queryParams?.transaction);
  }, [contract, search]);

  const handleModal = useCallback(() => setIsOpen((prev) => !prev), []);

  return (
    <section>
      <InnerContainer>
        <div className={styles.text}>
          you have got a branded Cossacks t-shirt!
          <div className={styles.buttonWrapper}>
            <Button onClick={handleModal} size="medium" type="outline">
              Get a T-shirt!
            </Button>
          </div>
        </div>
      </InnerContainer>
      <GetGiftModal transactionHash={transactionHash} open={isOpen} onClose={handleModal} />
    </section>
  );
};

export default TransactionGiftContainer;
