// extracted by mini-css-extract-plugin
export var closeBtn = "styles-module--closeBtn--28a20";
export var header = "styles-module--header--61089";
export var image = "styles-module--image--7c793";
export var imageWrapper = "styles-module--imageWrapper--8e49b";
export var info = "styles-module--info--f1616";
export var infoTitle = "styles-module--infoTitle--00e70";
export var infoWrapper = "styles-module--infoWrapper--c48f1";
export var inner = "styles-module--inner--b49f6";
export var modal = "styles-module--modal--94480";
export var rightCol = "styles-module--rightCol--49726";
export var subTitle = "styles-module--subTitle--d57df";
export var title = "styles-module--title--5a649";