export function nFormatter(num: string, digits: number) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (+num >= si[i].value) {
      break;
    }
  }
  return (+num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export const animateValue = ({
  end: number,
  duration,
  setState,
}: {
  end: string;
  duration: string;
  setState: (s: string) => void;
}) => {
  let start = 0;
  // first three numbers from props
  const end = parseInt(number.substring(0, 3));
  // if zero, return
  if (start === end) return;

  // find duration per increment
  const totalMilSecDur = parseInt(duration);
  const incrementTime = (totalMilSecDur / end) * 1000;

  // timer increments start counter
  // then updates count
  // ends if start reaches end
  const timer = setInterval(() => {
    start += 1;
    setState(String(start) + number.substring(3));
    if (start === end) clearInterval(timer);
  }, incrementTime);
};
