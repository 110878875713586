import React, { FC, memo } from 'react';

import Modal from '../../components/Modal';
import { INFT } from '../../constants/I_ORDER';
import OpenSeaLink from './components/OpenSeaLink';
import { CloseWhiteIcon } from '../../images/icons';
import * as styles from './styles.module.scss';

interface IMintPreviewModal {
  data: INFT;
  open: boolean;
  onClose: () => void;
}

const MintPreviewModal: FC<IMintPreviewModal> = ({ data, open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} modalClassName={styles.modal}>
      <div className={styles.inner}>
        <button className={styles.closeBtn} onClick={onClose}>
          <img src={CloseWhiteIcon} alt="" />
        </button>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={data.preview} alt={data.orderNumber.toString()} />
        </div>
        <div className={styles.rightCol}>
          <div className={styles.header}>
            <h2 className={styles.title}>CryptoCossack #{data.orderNumber}</h2>
            <p className={styles.subTitle}>
              Collection: <span>Primary</span>
            </p>
          </div>
          <div className={styles.infoWrapper}>
            <p className={styles.info}>
              <span className={styles.infoTitle}>Background</span>
              {data['Background'] || 'none'}
            </p>
            <p className={styles.info}>
              <span className={styles.infoTitle}>Right hand</span>
              {data['Right Hand'] || 'none'}
            </p>
            <p className={styles.info}>
              <span className={styles.infoTitle}>Thing behind the back</span>
              {data['Behind The Back'] || 'none'}
            </p>
            <p className={styles.info}>
              <span className={styles.infoTitle}>Left hand</span>
              {data['Left Hand'] || 'none'}
            </p>
            <p className={styles.info}>
              <span className={styles.infoTitle}>Clothes</span>
              {data['Clothes'] || 'none'}
            </p>
            <p className={styles.info}>
              <span className={styles.infoTitle}>Character</span>
              {data['Character'] || 'none'}
            </p>
            <p className={styles.info}>
              <span className={styles.infoTitle}>Hair</span>
              {data['Hair'] || 'none'}
            </p>
            <p className={styles.info}>
              <span className={styles.infoTitle}>Inscription</span>
              {data['Inscription'] || 'none'}
            </p>
            <p className={styles.info}>
              <span className={styles.infoTitle}>Accessories</span>
              {data['Accessories'] || 'none'}
            </p>
          </div>
          <OpenSeaLink url={data.link} />
        </div>
      </div>
    </Modal>
  );
};

export default memo(MintPreviewModal);
