import React, { FC } from 'react';

import Header from '../containers/Header';
import Footer from '../containers/Footer';
import PageContainer from '../containers/PageContainer';
import ThemeProvider from '../containers/ThemeProvider';
import WalletProvider from '../containers/WalletProvider';
import '../sass/basic.scss';
import SEO from '../components/SEO';
import Breadcrumbs from '../components/Breadcrumbs';
import TransactionBlock from '../containers/TransactionBlock';
import { IBreadcrumbsLink } from '../constants/IBREADCRUMBS_LINK';
import TransactionGiftContainer from '../containers/TransactionGiftContainer';

const breadcrumbsLinks: IBreadcrumbsLink[] = [
  {
    url: '/',
    title: 'Main page',
  },
  {
    url: '',
    title: 'Transaction',
  },
];

interface IPage {
  location: {
    search: string;
  };
}

const TransactionPage: FC<IPage> = ({ location }) => {
  return (
    <ThemeProvider>
      <WalletProvider>
        <PageContainer>
          <Header />
          <SEO />
          <TransactionGiftContainer search={location?.search} />
          <Breadcrumbs links={breadcrumbsLinks} />
          <TransactionBlock search={location?.search} />
          <Footer />
        </PageContainer>
      </WalletProvider>
    </ThemeProvider>
  );
};

export default TransactionPage;
