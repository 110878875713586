import React, { FC, useCallback, useState, useEffect } from 'react';
import Modal from '../../../../components/Modal';
import { CloseIcon, CheckedCircled } from '../../../../images/icons';
import * as styles from './styles.module.scss';
import GiftInnerBox from '../../../GiftInnerBox';
import Button from '../../../../components/Button';
import validateEmail from '../../../../utils/validation/fields/email';
import cn from 'classnames';
import axios from 'axios';
import { TSHIRT_REQUEST_URL } from '../../../../constants/URL';
import notification from '../../../../utils/services/notification';

interface IModal {
  open: boolean;
  onClose?: () => void;
  transactionHash?: string;
}

const INPUT_NAME = {
  EMAIL: 'email',
  PHONE: 'phone',
};

const enum ButtonTextObj {
  true = 'Sent',
  false = 'Send',
}

interface IFormState {
  data: {
    email: string;
    phone: string;
  };
  errors: {
    email: null | string | false;
  };
}

const INITIAL_FORM_VALUES = {
  data: {
    email: '',
    phone: '',
  },
  errors: {
    email: null,
  },
};

const GetGiftModal: FC<IModal> = ({ open, onClose, transactionHash }) => {
  const [isSent, setIsSent] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isSendAgainLoading, setIsSendAgainLoading] = useState<boolean>(false);

  const [formData, setData] = useState<IFormState>(INITIAL_FORM_VALUES);

  const handleChange = ({ target: { name, value } }: any) => {
    setData((prevState) => ({
      ...prevState,
      data: { ...prevState.data, [name]: value },
    }));
  };

  const resetForm = () => setData(INITIAL_FORM_VALUES);

  const handleCloseModal = useCallback(() => {
    if (onClose) onClose();
    setIsSent(false);
    resetForm();
  }, [onClose]);

  const handleEmailFocus = () =>
    setData((prevState) => ({
      ...prevState,
      errors: {
        email: null,
      },
    }));

  const requestTshirt = useCallback(async () => {
    const payload = {
      data: {
        email: formData.data.email,
        phone: formData.data.phone,
        transaction_hash: transactionHash || 'test',
      },
    };

    return axios.post(TSHIRT_REQUEST_URL, payload);
  }, [formData, transactionHash]);

  const handleSubmit = useCallback(async () => {
    try {
      if (isSent) return;
      // if (!transactionHash) return;
      setIsSubmitLoading(true);
      const emailError: any = validateEmail({
        value: formData.data.email,
        name: INPUT_NAME.EMAIL,
        required: true,
      });
      setData((prevState) => ({
        ...prevState,
        errors: {
          email: emailError,
        },
      }));
      if (!!emailError?.length) return;

      await requestTshirt();

      setIsSent(true);
    } catch (err) {
      const errorText = err?.error?.message || err?.message;
      notification.error(errorText);
    } finally {
      setIsSubmitLoading(false);
    }
  }, [formData, isSent, requestTshirt, transactionHash]);

  const sendAgain = useCallback(async () => {
    try {
      setIsSendAgainLoading(true);
      await requestTshirt();
    } catch (err) {
      const errorText = err?.error?.message || err?.message;
      notification.error(errorText);
    } finally {
      setIsSendAgainLoading(false);
    }
  }, [requestTshirt]);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  // just to be sure
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Modal modalClassName={styles.modal} open={open} onClose={handleCloseModal}>
      <div className={styles.inner}>
        <button className={styles.closeBtn} onClick={handleCloseModal}>
          <img src={CloseIcon} alt="" />
        </button>
        <GiftInnerBox />
        <div className={styles.content}>
          <div className={styles.title}>Thank you for the Mint!</div>
          <div className={styles.description}>
            We'll send you a form and instructions <br /> on how to get a Cossack T-shirt.
          </div>
          <div>
            {isSent ? (
              <>
                <div className={styles.formTitle}>Nice! Check your email</div>
                <div className={styles.formDescription}>
                  We already have sent you instructions to your email, please check it.
                </div>
              </>
            ) : (
              <>
                <div className={styles.formGroup}>
                  <label>email</label>
                  <div className={styles.inputWrapper}>
                    <input
                      type="email"
                      className={cn(styles.input, {
                        [styles.inputError]: formData?.errors?.email,
                      })}
                      name={INPUT_NAME.EMAIL}
                      placeholder="Enter your email"
                      onChange={handleChange}
                      onFocus={handleEmailFocus}
                    />
                    {formData?.errors?.email && <span className={styles.errorText}>{formData.errors.email}</span>}
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <label>phone</label>
                  <div className={styles.inputWrapper}>
                    <input
                      type="text"
                      className={styles.input}
                      name={INPUT_NAME.PHONE}
                      placeholder="Enter your phone number"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}

            <Button
              disabled={!!formData?.errors?.email?.length || isSubmitLoading}
              size="medium"
              onClick={handleSubmit}
            >
              {isSent ? (
                <div className={styles.buttonInner}>
                  <img src={CheckedCircled} alt="" className="gt_user_subscribed" />
                  <span>{ButtonTextObj[isSent.toString()]}</span>
                </div>
              ) : (
                ButtonTextObj[isSent.toString()]
              )}
            </Button>
            {isSent && (
              <div className={styles.footerForm}>
                Haven’t received it yet?{' '}
                <button disabled={isSendAgainLoading} onClick={sendAgain} className={styles.footerLink}>
                  Send again
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GetGiftModal;
