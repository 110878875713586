import React, { FC, memo } from 'react';
import { IBreadcrumbsLink } from '../../constants/IBREADCRUMBS_LINK';
import InnerContainer from '../InnerContainer';
import * as styles from './styles.module.scss';

interface IBreadcrumbs {
  links: IBreadcrumbsLink[];
}

const Breadcrumbs: FC<IBreadcrumbs> = ({ links }) => {
  return (
    <nav className={styles.breadcrumbs}>
      <InnerContainer>
        <ol className={styles.breadcrumbs_list}>
          {links.map(({ url, title }) => (
            <li className={styles.breadcrumbs_item} key={url}>
              {url ? (
                <a className={styles.breadcrumbs_link} href={url}>
                  {title}
                </a>
              ) : (
                <span className={styles.breadcrumbs_currentPage}>{title}</span>
              )}
            </li>
          ))}
        </ol>
      </InnerContainer>
    </nav>
  );
};

export default memo(Breadcrumbs);
