import React, { FC, ReactNode } from 'react';
import * as styles from './styles.module.scss';

interface IInnerContainer {
  children: ReactNode;
}

const InnerContainer: FC<IInnerContainer> = ({ children }) => {
  return <div className={styles.innerContainer}>{children}</div>;
};

export default React.memo(InnerContainer);
