import React, { FC, memo } from 'react';
import Button from '../../../../components/Button';
import { OpenSea } from '../../../../images/icons';

interface IOpenSeaLink {
  url: string;
}

const OpenSeaLink: FC<IOpenSeaLink> = ({ url }) => {
  return (
    <Button link href={url} size="medium" target="_blank" rel="noopener nofollow">
      <img src={OpenSea} alt="" />
      <span>View on Opensea</span>
    </Button>
  );
};

export default memo(OpenSeaLink);
