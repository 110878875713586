import React, { FC, useCallback, useEffect, useState, useMemo, CSSProperties } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import cn from 'classnames';
import { ethers } from 'ethers';

import InnerContainer from '../../components/InnerContainer';
import Button from '../../components/Button';
import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import { INFT } from '../../constants/I_ORDER';
import { ParsedNFTPrice } from '../../constants/NFT_PRICE';
import notification from '../../utils/services/notification';
import { nFormatter } from '../../utils/custom/numberUtils';
import MintPreview from './components/MintPreview';
import MintPreviewModal from '../../modals/MintPreviewModal';
import SkeletonLoader from '../../components/SkeletonLoader';
import { useWalletStore } from '../WalletProvider/context';
import { CURRENCIES_LIST, UPDATE_PURCHASE_NUMBERS } from '../../constants/URL';
import * as styles from './styles.module.scss';

const etherScanBaseURl = process.env.GATSBY_ETHERSCAN_URL || 'https://rinkeby.etherscan.io';
const openSeaBaseUrl = process.env.GATSBY_OPENSEA_URL || 'https://testnets.opensea.io';

const contractAddress = process.env.GATSBY_CONTRACT_ADDRESS || '0xC9aa1F9282DfAD26A91B8f654A9EF18C19ba79c2';

interface ITransaction {
  search: string;
}

const skeletonFlagArray: boolean[] = [];

// if no query param transaction, show some error and empty page or redirect to the main page
const TransactionBlock: FC<ITransaction> = ({ search }) => {
  const [transactions, setTransactions] = useState<INFT[]>([]);
  const [transactionHash, setTransactionHash] = useState<string>('');
  const [donatedAmount, setDonatedAmount] = useState<string>('0');
  const [transactionNumber, setTransactionNumber] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedNft, setSelectedNft] = useState<INFT | null>(null);
  const [isSkeleton, setIsSkeleton] = useState<boolean>(true);

  const { width: viewportWidth } = useWindowDimensions();
  const { contract, setEthersProvider } = useWalletStore();

  const collection = 'Primary';

  const queryTokenIds = useMemo(() => {
    const queryParams: any = queryString.parse(search?.slice(1));
    if (Object.keys(queryParams).length === 0) return null;
    return Array.isArray(queryParams?.ids) ? queryParams?.ids : [queryParams?.ids];
  }, [search]);

  const style = useMemo(() => {
    let columns = 4;

    if (viewportWidth <= 768 && viewportWidth > 500) {
      columns = 2;
    }

    return { '--preview-rows': Math.ceil(queryTokenIds?.length / columns) } as CSSProperties;
  }, [queryTokenIds, viewportWidth]);

  const backToMain = useCallback(() => {
    navigate('/');
    notification.error("You don't have any transactions!");
  }, []);

  const innerClassNames = cn(styles.inner, {
    [styles.inner_loading]: isSkeleton,
  });

  const handleLoadImage = useCallback(() => {
    skeletonFlagArray.push(true);

    if (skeletonFlagArray.length === queryTokenIds?.length) {
      setIsSkeleton(false);
    }
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const displayModalHandler = useCallback((nft: INFT) => {
    setSelectedNft(nft);
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (!contract) return;
    const queryParams: any = queryString.parse(search?.slice(1));
    if (Object.keys(queryParams).length === 0) return backToMain();
    (async () => {
      try {
        // @ts-ignore
        const tokenIds = Array.isArray(queryParams?.ids) ? queryParams?.ids : [queryParams?.ids];
        setTransactionNumber(`#${tokenIds[0]}${tokenIds.length > 1 ? ' ...' : ''}`);
        const currenciesList = await axios(CURRENCIES_LIST);
        const ethereumPrice = parseFloat(currenciesList.data.data.rates.USD);
        console.log(ParsedNFTPrice, ethereumPrice);
        // need to change on publicmint
        const price = await contract.whiteListPrice();
        console.log(price.toString());
        const rawDonatedAmount = ethereumPrice * tokenIds.length * ethers.utils.formatEther(price) * 0.5;
        console.log(rawDonatedAmount);
        setDonatedAmount(nFormatter(rawDonatedAmount.toString(), 2));

        setTransactionHash(queryParams?.transaction);
        const tokenData: any = await Promise.all(
          tokenIds.map(async (tokenId: number) => {
            const tokenURI = await contract.tokenURI(tokenId);
            const jsonResult = await axios.get(tokenURI);
            return jsonResult.data;
          }),
        );
        const formattedTokenData = tokenData.map((item) => {
          const cossackID = item?.name?.split('#')?.[1];
          const attrs = item.attributes.reduce((acc, item) => {
            acc[item.trait_type] = item.value;
            return acc;
          }, {});
          return {
            preview: item.image,
            orderNumber: cossackID,
            link: `${openSeaBaseUrl}/assets/${contractAddress}/${cossackID}`,
            ...attrs,
          };
        });
        setTransactions(formattedTokenData);
      } catch (err) {
        backToMain();
      }
    })();
  }, [backToMain, contract, search]);

  useEffect(() => {
    setEthersProvider();
    (async () => {
      try {
        await axios(UPDATE_PURCHASE_NUMBERS);
      } catch (err) {
        const errorText = err?.error?.message || err?.message;
        notification.error(errorText);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <InnerContainer>
        <header className={styles.titleWrapper}>
          <div className={styles.leftCol}>
            <h1 className={styles.title}>
              Thank you! you have successfully minted Crypto Cossacks {transactionNumber}
              {/*<br /> ${donatedAmount} have been donated to Ukraine*/}
            </h1>
            <div className={styles.info}>
              <p className={styles.infoItem}>
                <span className={styles.infoTitle}>Collection:</span>
                {collection}
              </p>
              <p className={styles.infoItem}>
                <span className={styles.infoTitle}>Quantity:</span>x{transactions.length}
              </p>
            </div>
          </div>
          <div>
            <Button
              size="medium"
              link
              href={`${etherScanBaseURl}/tx/${transactionHash}`}
              target="_blank"
              rel="noopener nofollow"
            >
              View Transaction
            </Button>
          </div>
        </header>
        <div className={innerClassNames} style={style}>
          {transactions.map((item) => (
            <MintPreview
              data={item}
              key={item.orderNumber}
              onClick={() => displayModalHandler(item)}
              onImageLoaded={handleLoadImage}
            />
          ))}
          {isSkeleton && (
            <div className={styles.skeletonWrapper}>
              {queryTokenIds?.map((id: string) => (
                <SkeletonLoader key={id} width={305} height={350}>
                  <rect width="305" height="305" fill="#F3F3F3" />
                  <rect y="327" width="35" height="17" fill="#F3F3F3" />
                </SkeletonLoader>
              ))}
            </div>
          )}
        </div>
      </InnerContainer>
      {selectedNft && <MintPreviewModal open={showModal} data={selectedNft} onClose={closeModal} />}
    </section>
  );
};

export default TransactionBlock;
