// extracted by mini-css-extract-plugin
export var buttonInner = "styles-module--buttonInner--eee66";
export var closeBtn = "styles-module--closeBtn--78b67";
export var content = "styles-module--content--8698f";
export var description = "styles-module--description--0d52f";
export var errorText = "styles-module--errorText--ecf2f";
export var footerForm = "styles-module--footerForm--2dec0";
export var footerLink = "styles-module--footerLink--2badf";
export var formDescription = "styles-module--formDescription--872a4";
export var formGroup = "styles-module--formGroup--495ba";
export var formTitle = "styles-module--formTitle--d2c8b";
export var inner = "styles-module--inner--5c31b";
export var input = "styles-module--input--7d88b";
export var inputError = "styles-module--inputError--1b61e";
export var inputWrapper = "styles-module--inputWrapper--5bf75";
export var modal = "styles-module--modal--91129";
export var title = "styles-module--title--4d143";