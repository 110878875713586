import React, { FC, memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { PreviewModal } from '../../../../images/icons';
import { INFT } from '../../../../constants/I_ORDER';
import * as styles from './styles.module.scss';

interface IMintPreview {
  data: INFT;
  onClick: () => void;
  onImageLoaded: () => void;
}

const MintPreview: FC<IMintPreview> = ({ data, onClick, onImageLoaded }) => {
  const handleSkeletonChange = () => {
    onImageLoaded();
  };

  return (
    <article className={styles.card}>
      <button className={styles.button} aria-label="See details" onClick={onClick}>
        <img src={PreviewModal} alt="" />
      </button>
      <LazyLoadImage
        className={styles.image}
        alt=""
        src={data.preview}
        afterLoad={handleSkeletonChange}
        onClick={onClick}
      />
      <p className={styles.id}>CryptoCossack #{data.orderNumber}</p>
    </article>
  );
};

export default memo(MintPreview);
